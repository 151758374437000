import React, { useEffect, useMemo, useState } from "react";
import "./CanIdentifier.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as InputSearch } from "./svg/InputSearch.svg";
import { ReactComponent as CanConfirmedSuccess } from "./svg/canSuccess.svg";
import { ReactComponent as DeleteCan } from "./svg/btn-remove_modal.svg";
import { ReactComponent as SaveCan } from "./svg/Save.svg";
import { ReactComponent as EditCan } from "./svg/btnEditor.svg";
import { ReactComponent as AddNewRecord } from "./svg/Add.svg";
import { ReactComponent as CanConfirmedError } from "./svg/canError.svg";
import { ReactComponent as AddNewCan } from "./svg/add_new_can.svg";
import { ReactComponent as UpdateCan } from "./svg/updateCan.svg";
import DataTable, { TableColumn } from "react-data-table-component";
import { CanIdentifierDTO, FunctionDTO } from "../../../../api/auth/apiClient";
import Select from "react-select";
import { Checkbox, Hint } from "@skbkontur/react-ui";
import { selectStyles } from "./styleSelector";
import { DataSendingPeriod, optionsCanType } from "../data";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../../Notification/notificationTypes";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import ModalNotification from "../../../CreateTransport/Modal_notification/ModalNotification";
import { customStylesCan } from "./stylesCan";

type CanIdentifierProps = {
  canIdentifiers: Array<CanIdentifierDTO>;
  selectedRow: FunctionDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  setCanIdentifiers: (canIdentifiers: CanIdentifierDTO[]) => void;
  setShowModalCan: (isShow: boolean) => void;
  getFunctionsList: (uvi: string, id: string | undefined) => void;
};

type ArrayCanIdentifierDTO = {
  array: CanIdentifierDTO[];
};
function CanIdentifier(props: CanIdentifierProps) {
  const intl = useIntl();
  const [showModalDeleteCan, setShowModalDeleteCan] = useState(false);
  const [showModalCancelAction, setShowModalCancelAction] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ArrayCanIdentifierDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return { array: props.canIdentifiers };
    }, [props]),
  });
  useEffect(() => {
    reset({ array: props.canIdentifiers });
  }, [props.canIdentifiers, reset]);

  const { fields, append, remove } = useFieldArray({ control, name: "array" });
  const [canIdentifierToDelete, setCanIdentifierToDelete] = useState<number>();
  const [canIdentifierCanIndexToDelete, setCanIdentifierCanIndexToDelete] =
    useState<number>();
  const onSubmit: SubmitHandler<ArrayCanIdentifierDTO> = async (data) => {
    let result;
    try {
      result = await mapClient.updateCanIdentifiers(data.array);
      if (result?.succeeded) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "changes_can_success" }),
        });
        await props.getFunctionsList(props.selectedRow.uvi, undefined);
      }
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({
          id: "changes_can_error",
        })}: ${error?.errors ? error.errors[0] : "Unknown error"}`,
      });
    }
  };
  function addNewRecord() {
    const newRecord = new CanIdentifierDTO({
      id: undefined,
      uvi: props.selectedRow.uvi,
      index: undefined,
      canId: "",
      period: undefined,
      protocol: undefined,
      extended: false,
      configurationConfirmed: false,
    });
    append(newRecord);
  }

  function removeRecord(arrayIndex: number, index: number | undefined) {
    if (index !== undefined) {
      watch(`array.${arrayIndex}.period`);
      setValue(`array.${arrayIndex}.period`, 0);
    } else {
      remove(arrayIndex);
    }
  }

  function onShowDeleteCan(arrayIndex: number, index: number | undefined) {
    setCanIdentifierToDelete(arrayIndex);
    setCanIdentifierCanIndexToDelete(index);
    setShowModalDeleteCan(true);
  }

  const columns: TableColumn<CanIdentifierDTO>[] = [
    {
      name: intl.formatMessage({ id: "canId" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <input
            {...register(`array.${index}.canId`, {
              required: intl.formatMessage({ id: "canId_validation" }),
              pattern: {
                value: /^[0-9a-fA-F]+$/,
                message: intl.formatMessage({ id: "canId_validation" }),
              },
              validate: (value) => {
                let maxValueExtendedTrue = 0x1fffffff;
                let maxValueExtendedFalse = 0x7ff;
                if (watch(`array.${index}.extended`) === true) {
                  if (value.length !== 8) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_true",
                    });
                  }
                  if (parseInt(value, 16) > maxValueExtendedTrue) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_true_maxValue",
                    });
                  }
                  return true;
                }
                if (watch(`array.${index}.extended`) === false) {
                  if (value.length !== 3) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_false",
                    });
                  }
                  if (parseInt(value, 16) > maxValueExtendedFalse) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_false_maxValue",
                    });
                  }
                  return true;
                }
              },
            })}
            type="text"
            id="canId"
            placeholder="CAN ID"
            className={`canId ${
              getValues(`array.${index}.period`) === 0 ? "disabled" : ""
            }`}
            disabled={getValues(`array.${index}.period`) === 0}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.canId && (
              <p>
                {(errors?.array ?? [])[index]?.canId?.message || "Errors!!!"}
              </p>
            )}
          </div>
        </div>
      ),
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "protocol" }),
      cell: (can, index) => (
        <div className="flex-wrap absolute">
          <Controller
            name={`array.${index}.protocol`}
            control={control}
            rules={{
              required: intl.formatMessage({ id: "can_input_required" }),
            }}
            render={({ field: { onChange, name, value } }) => (
              <Select
                name={name}
                styles={selectStyles}
                isDisabled={getValues(`array.${index}.period`) === 0}
                isSearchable={false}
                isClearable={false}
                options={optionsCanType.map((can) => ({
                  value: can.value,
                  label: can.label,
                }))}
                onChange={(value) => onChange(value?.value)}
                value={optionsCanType
                  .map((can) => {
                    return { value: can.value, label: can.label };
                  })
                  .find((can) => can.value === value)}
                placeholder={intl.formatMessage({
                  id: "placeholder_choose_can_protocol",
                })}
              />
            )}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.protocol && (
              <p>
                {(errors?.array ?? [])[index]?.protocol?.message || "Errors!!!"}
              </p>
            )}
          </div>
        </div>
      ),
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "can_period" }),
      cell: (can, index) => (
        <div className="flex-wrap absolute">
          <input
            {...register(`array.${index}.period`, {
              required: intl.formatMessage({ id: "can_input_required" }),
              pattern: {
                value: /^\d+([.]\d)?$/,
                message: intl.formatMessage({ id: "can_period_validation" }),
              },
            })}
            disabled={getValues(`array.${index}.period`) === 0}
            className="can_period"
            id={"period"}
            type={"text"}
            placeholder={intl.formatMessage({
              id: "placeholder_choose_period",
            })}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.period && (
              <p>
                {(errors?.array ?? [])[index]?.period?.message || "Errors!!!"}
              </p>
            )}
          </div>
        </div>
      ),
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "can_type_extended" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.extended`}
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <Checkbox
                name={name}
                disabled={getValues(`array.${index}.period`) === 0}
                onChange={(value) => onChange(value.target.checked)}
                checked={value}
              />
            )}
          />
        </div>
      ),
      center: true,
    },
    {
      name: intl.formatMessage({ id: "can_action" }),
      cell: (can, arrayIndex) => (
        <>
          {can.configurationConfirmed === false ? (
            <Hint
              text={intl.formatMessage({
                id: "can_configurationConfirmed_error",
              })}
            >
              <CanConfirmedError className="mr-2.5" />
            </Hint>
          ) : (
            <Hint
              text={intl.formatMessage({
                id: "can_configurationConfirmed_success",
              })}
            >
              <CanConfirmedSuccess className="mr-2.5" />
            </Hint>
          )}
          <DeleteCan
            className="mr-2"
            onClick={() => onShowDeleteCan(arrayIndex, can.index)}
          />
          {/*<EditCan onClick={() => editRecord(arrayIndex)} className="mr-2" />*/}
        </>
      ),
    },
  ];

  return (
    <>
      {showModalDeleteCan ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalDeleteCan(false)}
            modalQuestion={intl.formatMessage({
              id: "delete_can_identifier_question",
            })}
            onNoModalActions={() => {
              removeRecord(
                canIdentifierToDelete!,
                canIdentifierCanIndexToDelete
              );
              setShowModalDeleteCan(false);
            }}
          />
        </div>
      ) : null}

      {showModalCancelAction ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalCancelAction(false)}
            modalQuestion={intl.formatMessage({
              id: "cancel_action_question",
            })}
            onNoModalActions={() => props.setShowModalCan(false)}
          />
        </div>
      ) : null}

      <div className="modal_can">
        <div className="modal_can_container">
          <form onSubmit={handleSubmit(onSubmit)} className="modal_can_form">
            <div className="modal_can_header">
              <div className="modal_can_form_title">
                <h3>
                  <FormattedMessage id="changes_CanId" />
                </h3>
                <img
                  src="/image/btn-remove/btn-remove.svg"
                  alt="Close form"
                  onClick={() => props.setShowModalCan(false)}
                />
              </div>
              {/*<div className="modal_can_form_search mt-3">
                <div className="can_search flex">
                  <div className="searchImageCan">
                    <InputSearch />
                  </div>
                  <input
                    type="text"
                    className="searchInputCan"
                    placeholder={intl.formatMessage({ id: "search_CanId" })}
                  />
                </div>
              </div>*/}
            </div>
            <div className="modal_can_form_body mt-3.5">
              <div className="flex justify-start">
                <button
                  type="button"
                  className="add_new_record"
                  onClick={addNewRecord}
                >
                  <AddNewCan className="ml-2.5" />
                  {/* <AddNewRecord className="mr-1" />*/}
                  <span className="ml-2.5">
                    {intl.formatMessage({ id: "add_can" })}
                  </span>
                </button>
                <div className="ml-5">
                  <button
                    type="button"
                    className="update_can"
                    onClick={() =>
                      props.getFunctionsList(props.selectedRow.uvi, undefined)
                    }
                  >
                    <UpdateCan className="ml-7" />
                    <span className="ml-2">
                      {intl.formatMessage({ id: "update" })}
                    </span>
                  </button>
                </div>
              </div>
              <div className="mt-3.5">
                <DataTable
                  columns={columns}
                  data={fields}
                  noDataComponent={intl.formatMessage({ id: "empty_table" })}
                  fixedHeader
                  striped={false}
                  fixedHeaderScrollHeight="450px"
                  pointerOnHover={true}
                  persistTableHead
                  customStyles={customStylesCan}
                />
              </div>
            </div>
            <div className="modal_can_form_footer mt-5">
              <div className="flex items-center">
                <div>
                  <button type="submit" className="btn">
                    <SaveCan />
                    <span className="ml-2">
                      <FormattedMessage id="save" />
                    </span>
                  </button>
                </div>
                <div className="ml-7">
                  <button
                    className="cancel"
                    type="button"
                    onClick={() => setShowModalCancelAction(true)}
                  >
                    <span>
                      <FormattedMessage id="cancel" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CanIdentifier;
