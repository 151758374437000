import React, { useState } from "react";
import "../Login.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { EntryPageViewState } from "../LoginTypes";
import { ResponseDTO, UserRegisterDTO } from "../../../api/auth/apiClient";
import { authClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import { SubmitHandler, useForm } from "react-hook-form";

type PropsSingUp = {
  changeView: (view: EntryPageViewState) => void;
  onChangeNotificationState: (state: NotificationState) => void;
};

class UserRegisterDTOWithConfirm extends UserRegisterDTO {
  confirmPassword: string;
  constructor(confirmPassword: string) {
    super();
    this.confirmPassword = confirmPassword;
  }
}
function SingForm(props: PropsSingUp) {
  const intl = useIntl();
  const [accept, setAccept] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<UserRegisterDTOWithConfirm>({ mode: "onTouched" });
  const onSubmit: SubmitHandler<UserRegisterDTO> = async (data) => {
    let result = new ResponseDTO();
    try {
      result = await authClient.register(new UserRegisterDTO(data));
    } catch {}
    if (result.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "signUp_success" }),
      });
      props.changeView(EntryPageViewState.logIn);
    } else {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "signUp_errors" }),
      });
    }
  };
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h2>
            <FormattedMessage id="signUp" />
          </h2>
        </div>
        <fieldset>
          <ul>
            <li>
              <label htmlFor="companyToken">
                <FormattedMessage id="signUp_companyToken" />
              </label>
              <input
                {...register("companyToken", {
                  required: intl.formatMessage({ id: "input_required" }),
                })}
                type="text"
                id="companyToken"
              />
              <div className="mt-0.5">
                {errors?.companyToken && (
                  <p>{errors?.companyToken?.message || "Errors"}</p>
                )}
              </div>
            </li>
            <li>
              <label htmlFor="email">
                <FormattedMessage id="signUp_email" />
              </label>
              <input
                {...register("email", {
                  required: intl.formatMessage({ id: "input_required" }),
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: intl.formatMessage({ id: "validate_email" }),
                  },
                })}
                type="email"
                id="email"
              />
              <div className="mt-0.5">
                {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
              </div>
            </li>
            <li>
              <label htmlFor="password">
                <FormattedMessage id="signUp_password" />
              </label>
              <input
                {...register("password", {
                  required: intl.formatMessage({ id: "input_required" }),
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                    message: intl.formatMessage({ id: "validate_password" }),
                  },
                })}
                type="password"
                id="password"
              />
              <div className="mt-0.5">
                {errors?.password && (
                  <p>{errors?.password?.message || "Errors"}</p>
                )}
              </div>
            </li>
            <li>
              <label htmlFor="confirmPassword">
                <FormattedMessage id="singUp_password_repeat" />
              </label>
              <input
                {...register("confirmPassword", {
                  required: intl.formatMessage({ id: "input_required" }),
                  validate: (value) => {
                    if (watch("password") !== value) {
                      return intl.formatMessage({ id: "match_password" });
                    }
                  },
                })}
                type="password"
                id="confirmPassword"
              />
              <div className="mt-0.5">
                {errors?.confirmPassword && (
                  <p>{errors?.confirmPassword?.message || "Errors"}</p>
                )}
              </div>
            </li>
          </ul>
        </fieldset>
        <button className="mt-5" type="submit">
          <FormattedMessage id="signUp_submit" />
        </button>
        <div className="mt-2 border-t">
          <div className="mt-2">
            <span>
              <FormattedMessage id="signUp_question" />
            </span>
            <a
              className="ml-2"
              onClick={() => props.changeView(EntryPageViewState.logIn)}
              href="#"
            >
              <FormattedMessage id="signUp_logIN" />
            </a>
          </div>
        </div>
      </form>
      <div className="user_accept">
        <div>
          <input
            className="mr-2"
            checked={accept}
            onChange={() => setAccept(!accept)}
            type="checkbox"
            name="accept"
          />
        </div>
        <div>
          <a href="https://sayoratec.com/privacy">
            <p>
              <FormattedMessage id="accept" />
            </p>
          </a>
        </div>
      </div>
    </>
  );
}

export default SingForm;
