import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as BtnSve } from "./svg/Save.svg";

function ButtonSave() {
  return (
    <button type="submit" className="btn ml-5">
      <BtnSve />
      <span className="ml-2.5">
        <FormattedMessage id="save" />
      </span>
    </button>
  );
}

export default ButtonSave;