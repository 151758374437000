import React, { useState } from "react";
import "./Login.scss";
import Notification from "../Notification/Notification";
import LocaleSelector from "../LocaleSelector/LocaleSelector";
import { EntryPageViewState, LoginProps } from "./LoginTypes";
import LoginFormContainer from "./LoginFormContainer";
import { ReactComponent as EntryLogo } from "./svg/logoEntry.svg";

function Login(props: LoginProps) {
  const [currentView, setCurrentView] = useState(EntryPageViewState.logIn);
  const [rememberCheckBox, setRememberCheckBox] = useState(false);

  function changeView(view: EntryPageViewState) {
    setCurrentView(view);
  }
  function onRememberCheckBox(e: React.ChangeEvent<HTMLInputElement>) {
    setRememberCheckBox(e.target.checked);
  }
  return (
    <div>
      <div className="entryPage grid">
        <div className="entryPageLogo">
            <EntryLogo />
        </div>
        <LoginFormContainer
          currentView={currentView}
          rememberCheckBox={rememberCheckBox}
          changeView={changeView}
          onRememberCheckBox={onRememberCheckBox}
          onChangeNotificationState={props.onChangeNotificationState}
        />
        <div className="col-start-2 row-start-3 justify-self-center mt-11">
          <LocaleSelector
            currentLocale={props.currentLocale}
            changeCurrentLocale={props.changeCurrentLocale}
          />
        </div>
      </div>
      <Notification
        showNotification={props.showNotification}
        onChangeNotificationState={props.onChangeNotificationState}
      />
    </div>
  );
}

export default Login;
