import React, { useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import "./UserPage.scss";
import { RoleDTO, UserDTO } from "../../api/auth/apiClient";
import { mapClient } from "../../api/auth/AxiosInstanse";
import { ReactComponent as Confirmed } from "./svg/confirmed.svg";
import { ReactComponent as NotConfirmed } from "./svg/refuse.svg";
import { ReactComponent as ReturnToken } from "./svg/return_token_user.svg";
import { ReactComponent as ReturnAllTokens } from "./svg/return_all_token.svg";
import { ReactComponent as AddUser } from "./svg/addUser.svg";
import { ReactComponent as DeleteUser } from "./svg/trashCanUser.svg";
import { ReactComponent as EditRole } from "./svg/edit.svg";
import { userPageStyles } from "./userPageStyles";
import ModalNotification from "../../components/CreateTransport/Modal_notification/ModalNotification";
import { Hint } from "@skbkontur/react-ui";
import CreateEditUser from "./CreateEditUser/CreateEditUser";
import { FilterComponent } from "./FilterComponent";
import {
  caseCreationDateSort,
  caseRefreshTokenExpiryTimeSort,
} from "./FunctionsSort";
import Loader from "./Loader";
import SelectAllTransferRoles from "./ChangeUserRole/SelectAllTransferRoles";
import { useIntl } from "react-intl";
import {
  NotificationState,
  NotificationType,
} from "../Notification/notificationTypes";
import Notification from "../Notification/Notification";

type UserPageProps = {
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
};
type PaginationComponentOptionsType = {
  rowsPerPageText: string;
  rangeSeparatorText: string;
  selectAllRowsItem: boolean;
  selectAllRowsItemText: string;
};

function UserPage(props: UserPageProps) {
  const [users, setUsers] = useState<Array<UserDTO>>([]);
  const [selectedUser, setSelectedUser] = useState<UserDTO>({} as UserDTO);
  const [showModalUpdateToken, setShowModalUpdateToken] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [allRoles, setAllRoles] = useState<Array<RoleDTO>>([]);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
  const [showModalEditRole, setShowModalEditRole] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [pending, setPending] = useState(true);
  const intl = useIntl();

  /*Получение списка юзеров*/
  async function getUsers() {
    let response;
    try {
      response = await mapClient.getUsers();
      setUsers(response.data ?? []);
      const foundSelectedUser =
        response.data?.find((u) => u.id === selectedUser?.id) ??
        ({} as UserDTO);
      setSelectedUser(foundSelectedUser);
      let userRole;
      userRole = await mapClient.getRoles();
      setAllRoles(userRole.data ?? []);
    } catch {}
  }
  useEffect(() => {
    getUsers();
  }, []);

  /*Удаление юзера*/
  async function deleteUser(id: string) {
    setShowModalDeleteUser(false);
    try {
      await mapClient.deleteUser(id);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "user_delete_user_success" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "user_delete_user_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await getUsers();
  }

  /*Лоадер*/
  useEffect(() => {
    const timeout = setTimeout(() => {
      setUsers(users);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [users]);
  /*Отзыв токена пользователя*/
  async function revokeToken(userId: string) {
    setShowModalUpdateToken(false);
    try {
      await mapClient.revokeToken(userId);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "user_revoke_token_success" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "user_revoke_token_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await getUsers();
  }
  /*Отзыв всех токенов*/
  async function revokeAllTokens() {
    setShowModalUpdateToken(false);
    try {
      await mapClient.revokeAllTokens();
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "user_revoke_all_token_success" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({
          id: "user_revoke_all_token_error",
        })}: ${error?.errors ? error.errors[0] : "Unknown error"}`,
      });
    }
    await getUsers();
  }
  /*Модальное окно для обновления токена*/
  function onShowModalUpdateToken(user: UserDTO) {
    setSelectedUser(user);
    setShowModalUpdateToken(true);
  }
  /*Модальное окно для удаления юзера*/
  function onShowModalDeleteUser(user: UserDTO) {
    setSelectedUser(user);
    setShowModalDeleteUser(true);
  }
  /*Фильтрация юзеров*/
  const filteredUsers = users.filter(
    (user) =>
      user.id?.toLowerCase().includes(filterText.toLowerCase()) ||
      user.email?.toLowerCase().includes(filterText.toLowerCase()) ||
      user.creationDate
        ?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        ?.toLowerCase()
        .includes(filterText.toLowerCase()) ||
      user.roles
        ?.map((r) => r.name)
        .join(", ")
        ?.toLowerCase()
        .includes(filterText.toLowerCase()) ||
      user.refreshTokenExpiryTime
        ?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        ?.toLowerCase()
        .includes(filterText.toLowerCase())
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions: PaginationComponentOptionsType = {
    rowsPerPageText: intl.formatMessage({ id: "table_page_text" }),
    rangeSeparatorText: "-",
    selectAllRowsItem: true,
    selectAllRowsItemText: intl.formatMessage({ id: "table_select_allPages" }),
  };

  /*Колонки таблицы юзеров*/
  const columns: TableColumn<UserDTO>[] = [
    /*{
      name: intl.formatMessage({ id: "user_id" }),
      selector: (user) => user.id ?? "",
      sortable: true,
      compact: true,
      wrap: true,
      grow: 3,
    },*/
    {
      name: intl.formatMessage({ id: "user_email" }),
      selector: (user) => user.email ?? "-",
      sortable: true,
      grow: 2,
      compact: true,
      wrap: true,
    },
    {
      name: intl.formatMessage({ id: "user_email_status" }),
      selector: (user) =>
        user.emailConfirmed
          ? intl.formatMessage({ id: "user_email_confirmed" })
          : intl.formatMessage({ id: "user_email_notConfirmed" }),
      cell: (user) =>
        user.emailConfirmed ? (
          <Hint text={intl.formatMessage({ id: "user_email_confirm" })}>
            <Confirmed />
          </Hint>
        ) : (
          <Hint text={intl.formatMessage({ id: "user_email_not_confirm" })}>
            <NotConfirmed />
          </Hint>
        ),
      compact: true,
      sortable: true,
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "user_creation_date" }),
      selector: (user) =>
        user.creationDate?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }) ?? "-",
      sortable: true,
      sortFunction: caseCreationDateSort,
      compact: true,
      wrap: true,
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "user_roles" }),
      selector: (user) => user.roles?.map((r) => r.name).join(", ") ?? "",
      sortable: true,
    },
    {
      cell: (selectedUser) => (
        <>
          <Hint text={intl.formatMessage({ id: "change_role" })}>
            <EditRole
              onClick={() => {
                setSelectedUser(selectedUser);
                setShowModalEditRole(true);
              }}
            />
          </Hint>
        </>
      ),
    },
    {
      name: intl.formatMessage({ id: "user_refresh_token_expiry_time" }),
      selector: (user) =>
        user.refreshTokenExpiryTime?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }) ?? "-",
      sortable: true,
      sortFunction: caseRefreshTokenExpiryTimeSort,
      wrap: true,
      compact: true,
      grow: 2,
    },
    {
      cell: (user) => (
        <>
          <Hint text={intl.formatMessage({ id: "user_revoke_token" })}>
            <ReturnToken
              className="mr-3"
              onClick={() => onShowModalUpdateToken(user)}
            />
          </Hint>
          <DeleteUser onClick={() => onShowModalDeleteUser(user)} />
        </>
      ),
    },
  ];

  return (
    <>
      {showCreateUser ? (
        <CreateEditUser
          setShowCreateUser={setShowCreateUser}
          getUsers={getUsers}
          allRoles={allRoles}
          selectedUser={selectedUser}
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
        />
      ) : null}
      {showModalUpdateToken ? (
        <div>
          <ModalNotification
            modalQuestion={
              selectedUser.id
                ? intl.formatMessage({ id: "user_revoke_token_question" })
                : intl.formatMessage({ id: "user_revoke_all_token_question" })
            }
            onYesModalActions={() => setShowModalUpdateToken(false)}
            onNoModalActions={
              selectedUser.id
                ? () => revokeToken(selectedUser.id!)
                : () => revokeAllTokens()
            }
          />
        </div>
      ) : null}
      {showModalDeleteUser ? (
        <div>
          <ModalNotification
            modalQuestion={intl.formatMessage({
              id: "user_delete_user_question",
            })}
            onYesModalActions={() => setShowModalDeleteUser(false)}
            onNoModalActions={() => deleteUser(selectedUser.id!)}
          />
        </div>
      ) : null}
      {showModalEditRole ? (
        <div>
          <SelectAllTransferRoles
            users={users}
            selectedUser={selectedUser}
            setShowModalEditRole={() => setShowModalEditRole(false)}
            allRoles={allRoles}
            getUsers={getUsers}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
          />
        </div>
      ) : null}
      <div className="usersList">
        <div className="usersContainer">
          <div className="usersHeader">
            <h2 className="usersTitle">
              {intl.formatMessage({ id: "user_list_title" })}
            </h2>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                className="btn mr-4"
                onClick={() => {
                  setSelectedUser({} as UserDTO);
                  setShowCreateUser(true);
                }}
              >
                <AddUser className="mr-2" />
                <span>{intl.formatMessage({ id: "create_new_user" })}</span>
              </button>
            </div>
            <div className="flex">
              {/* <button className="cancel flex mr-4">
                <DeleteUser className="mr-2" />
                <span>Удалить</span>
              </button>*/}
              <button
                onClick={() => setShowModalUpdateToken(true)}
                className="cancel flex"
              >
                <ReturnAllTokens className="mr-2" />
                <span>{intl.formatMessage({ id: "revoke" })}</span>
              </button>
            </div>
          </div>
          <div className="users_body">
            <DataTable
              columns={columns}
              data={filteredUsers}
              defaultSortFieldId={4}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={userPageStyles}
              fixedHeader={true}
              fixedHeaderScrollHeight="400px"
              striped
              pagination={true}
              paginationPerPage={150}
              /* paginationRowsPerPageOptions={[30, 50]}*/
              paginationComponentOptions={paginationComponentOptions}
              highlightOnHover={true}
              pointerOnHover={true}
              noDataComponent={intl.formatMessage({ id: "table_empty" })}
              onRowDoubleClicked={(selectedUser) => {
                setSelectedUser(selectedUser);
                setShowCreateUser(true);
              }}
              progressPending={pending}
              progressComponent={<Loader />}
              /*selectableRows={(selected)=>{}}
              selectableRowsHighlight={true}*/
            />
          </div>
        </div>
        <Notification
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
        />
      </div>
    </>
  );
}

export default UserPage;
