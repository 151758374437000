import React, { useEffect, useState } from "react";
import "./FunctionHistory.scss";
import Select from "react-select";
import Toggle from "../TransportTable/Toggle/Toggle";
import DataTable, { TableColumn } from "react-data-table-component";
import { FunctionDTO, FunctionResultDTO } from "../../../api/auth/apiClient";
import { customStyles } from "../transportTypes/styles";
import { DataHistoryPeriod } from "./options";
import { DataCollectionPeriod } from "../Fuction/data";
import { FormattedMessage, useIntl } from "react-intl";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import { functionTableStyle } from "./styles_function_table";

type FunctionHistoryProps = {
  setShowFunctionHistory: (showFunctionHistory: boolean) => void;
  selectedRow: FunctionDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
};

function FunctionHistory(props: FunctionHistoryProps) {
  const [hexSelected, setHexEnabled] = useState(false);
  const [timePeriod, setTimePeriod] = useState(DataCollectionPeriod[0]);
  const [functionHistory, setFunctionHistory] = useState<FunctionDTO>();
  const [results, setResults] = useState<Array<FunctionResultDTO>>();
  const intl = useIntl();
  const StType = [
    {
      label: `\u{1F534} ${intl.formatMessage({ id: "status_error_table" })}`,
      value: "Failed",
    },
    {
      label: `\u{1F7E2} ${intl.formatMessage({ id: "status_success_table" })}`,
      value: "Success",
    },
    {
      label: `\u{1F535} ${intl.formatMessage({
        id: "status_undefined_table",
      })}`,
      value: "Undefined",
    },
  ];
  async function getFunctionHistory(id: string, period: number | undefined) {
    try {
      const response = await mapClient.getFunctionHistory(id, period);
      setFunctionHistory(response.data?.function);
      setResults(response.data?.results ?? []);
    } catch (error) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "data_receiving_error" }),
      });
    }
  }
  useEffect(() => {
    getFunctionHistory(props.selectedRow.id!, timePeriod.value);
  }, []);

  const columns: TableColumn<FunctionResultDTO>[] = [
    {
      name: intl.formatMessage({ id: "date_time" }),
      selector: (results) =>
        results.date!.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }),
      sortable: true,
      wrap: true,
      compact: true,
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "status" }),
      selector: (results) =>
        StType.find((s) => s.value === results.status?.name)?.label ?? "-",
      sortable: true,
      compact: true,
    },
    {
      name: intl.formatMessage({ id: "result" }),
      selector: (results) =>
        hexSelected ? results.hexResult ?? "-" : results.result ?? "-",
      sortable: true,
      center: true,
    },
  ];

  return (
    <div className="overlay_history">
      <div className="functionHistoryContainer">
        <div className="functionHistoryForm">
          <div className="functionHistoryHeader">
            <h2>{props.selectedRow.name}</h2>
            <img
              src="/image/btn-remove/btn-remove.svg"
              alt="Close form"
              onClick={() => props.setShowFunctionHistory(false)}
            />
          </div>
          <div className="choiceTimePeriod">
            <div className="choicePeriod">
              <div className="choiceTitle">
                <h3>
                  <FormattedMessage id="choice_period_Title" />
                </h3>
              </div>
              <div className="timePeriod mt-2">
                <Select
                  styles={customStyles}
                  options={DataHistoryPeriod}
                  isSearchable={false}
                  isClearable={false}
                  value={timePeriod}
                  onChange={async (e) => {
                    setTimePeriod(e!);
                    await getFunctionHistory(props.selectedRow.id!, e!.value);
                  }}
                />
              </div>
            </div>
            <div className="mt-5 toggle">
              <Toggle hexSelected={hexSelected} setHexEnabled={setHexEnabled} />
            </div>
          </div>

          <div className="functionHistoryTable mt-5">
            <DataTable
              columns={columns}
              data={results!}
              customStyles={functionTableStyle}
              noDataComponent={intl.formatMessage({ id: "empty_table" })}
              fixedHeader
              fixedHeaderScrollHeight="300px"
              striped
              highlightOnHover
              pointerOnHover
              persistTableHead
              /*pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15]}*/
            />
          </div>
          <div className="formFooter mt-6">
            <button
              className="btn"
              type="button"
              onClick={async () => {
                await getFunctionHistory(
                  props.selectedRow.id!,
                  timePeriod.value
                );
              }}
            >
              <span>
                <FormattedMessage id="update" />
              </span>
            </button>
            <button
              className="cancel"
              type="button"
              onClick={() => props.setShowFunctionHistory(false)}
            >
              <span>
                <FormattedMessage id="close" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FunctionHistory;
