import "../../../../styles/variables.scss";
export const optionsValue = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
];

export const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 44,
    border: "$Border2",
    borderRadius: 4,
    background: "$BgColor",
    cursor: "pointer",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    fontWeight: 400,
    fontSize: 14,
    color: "#000",
  }),
  option: (base: any) => ({
    ...base,
    cursor: "pointer",
    padding: "5px 10px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    fontWeight: 400,
    fontSize: 14,
  }),
};

