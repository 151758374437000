import { DataType } from "../../../api/auth/apiClient";

/*export const StType = [
    {label: "\u{1F534} Error", value: "Failed"},
    {label: "\u{1F7E2} Success", value: "Success"},
    {label: "\u{1F535} Undefined", value: "Undefined"}
]*/

export const NameDataType = [
  { label: "bit", value: DataType.Bit},
  { label: "byte", value: DataType.Byte},
  { label: "2-Byte", value: DataType.TwoByte},
];